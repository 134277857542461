import { defineStore } from 'pinia';
import { Nullable } from '@/types/generic';

type ToastType = 'success' | 'error';
type ToastPosition = 'top' | 'bottom';

interface State {
  isActive: boolean;
  persist: boolean;
  message: string;
  position: ToastPosition;
  type: ToastType;
}

export const useToastStore = defineStore('toast', {
  state: (): State => ({
    isActive: false,
    persist: false,
    message: '',
    position: 'top',
    type: 'success',
  }),
  actions: {
    display(message: string, type: Nullable<ToastType> = null, persist: boolean = false) {
      this.message = message;
      if (type) {
        this.type = type;
      }
      this.persist = persist;
      this.isActive = true;
    },
    hide() {
      this.message = '';
      this.isActive = false;
      this.persist = false;
    },
  },
});
